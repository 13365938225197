:root {
   --primary-color: #004a8f;
   --secondary-color: #c0cfd8;
   --text-color: #fdfdfd;
   --text-title-category: #303030;

   --white-700: #fdfdfd;
   --gray-800: #303030;
   --text-categories: #004a8f;
   --background-app: #fdfdfd;
   --text-primary: #303030;
   --background-categories: #c0cfd8;
   --text-label-price-product: #2e2e2e;
   --icon-cart: #2e2e2e;
   --color-dark: #fdfdfd;
   --background-input: #fdfdfd;
   --color-star-review: #505050;
   --text-sub: #505050;
   --background-all-you-can-eat: #5f9ea0;
   --input-search: #353535;
   --background-cart: #fdfdfd;
   --border-products: #e3e3e3;
   --disabled: #dddddd;
   --disabled-text: #6b6b6b;

   --background-login: #fdfdfde6;

   --text-observation: #6b6b6b;

   --red: #ff7c7c;
   --green: #46a146;
   --yellow: #ffc118;

   --image-background: url('./assets/images/garcom.png');
   --image-background2: url('./assets/images/garcom2.png');
}

.dark-mode:root {
   --primary-color: #303030 !important;
   --secondary-color: #3d3d3d !important;
   --text-color: #fdfdfd !important;
   --text-title-category: #fdfdfd !important;

   --white-700: #fdfdfd;
   --gray-800: #303030;
   --text-categories: #bea157;
   --background-input: #3d3d3d;
   --background-app: #535353;
   --text-primary: #fdfdfd;
   --background-categories: #3d3d3d;
   --text-label-price-product: #bea157;
   --color-dark: #bea157;
   --text-sub: #d8d8d8;
   --color-star-review: #d8d8d8;
   --background-all-you-can-eat: #223939;
   --input-search: #f3f3f3;
   --background-cart: #444444;
   --icon-cart: #bea157;
   --border-products: #646464;
   --disabled: #dddddd;
   --disabled-text: #6b6b6b;

   --background-login: #303030cf;

   --text-observation: #acacac;

   --red: #c05c5c;
   --green: #4e834e;
   --yellow: #f8c953;

   --image-background: url('./assets/images/garcom-dark.png');
   --image-background2: url('./assets/images/garcom2-dark.png');
}

* {
   font-size: 1rem;
   margin: 0;
   padding: 0;
   font-size: 1rem;
   box-sizing: border-box;

   &::-webkit-scrollbar {
      width: 0;
   }

   &::-webkit-scrollbar-track {
      background: none;
   }

   &::-webkit-scrollbar-thumb {
      background: none;
   }
}

html,
body {
   font-family: 'Poppins', sans-serif !important;
   color: var(--gray-800);
   background: var(--background-app);
   font-size: 97.75%;
   height: 100vh;
   height: calc(var(--vh, 1vh) * 100);
}

html {
   scroll-behavior: smooth;
}

:target {
   scroll-margin-top: 0.8em;
}

input {
   font: 400 1rem 'Poppins', sans-serif;
   width: 100%;
   background: var(--background-input);
   color: var(--text-primary);
}

textarea {
   font: 400 1rem 'Poppins', sans-serif;
   width: 100%;
   background: var(--background-input);
   color: var(--text-primary);
}

input[type='radio'] {
   margin: 0 !important;
}

button {
   border-radius: 0.3125rem;
   height: 3.125rem;
   border: 0;
   cursor: pointer;
   font-family: 'Poppins', sans-serif !important;
   color: var(--text-color);

   &:disabled {
      background: var(--disabled) !important;
      color: var(--disabled-text) !important;
   }
}

a {
   text-decoration: none;
}

li {
   list-style-type: none;
}

.label-input {
   font-size: 1rem;
   color: var(--text-primary);
}

//-modal-----------------------------------------------------------

.modal {
   background: var(--background-app);
   border-radius: 0.5rem;
   box-shadow: 0 0.125rem 0.5rem #333;
   min-width: 20rem;
   position: fixed;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 11;
   color: var(--text-primary);

   .modal-content {
      padding: 1.5rem;

      button {
         width: 100%;
         margin-top: 1.25rem;
         background: var(--primary-color);
      }
   }

   &.multiple-dialog {
      z-index: 13;
   }
}

.overlay {
   background: rgba($color: #000000, $alpha: 0.7);
   height: 100vh;
   left: 0;
   position: fixed;
   top: 0;
   width: 100vw;
   z-index: 10;

   &.multiple-dialog {
      z-index: 12;
   }
}

.header-modal {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 0.625rem;

   .title-modal {
      font-size: 0.8rem;
   }

   i {
      font-size: 1.3rem;
      color: var(--text-primary);

      &.icon-close {
         color: var(--red);
         font-size: 2rem;
      }
   }
}

.group-buttons-footer {
   position: absolute;
   bottom: 0;
   width: 100%;
   padding: 1.5rem;
   gap: 1.875rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   background: var(--background-app);
   border-top: 0.0625rem solid var(--border-products);

   .btn-add {
      width: 100%;
      background: var(--primary-color);
      color: var(--text-color);
   }
}

.btn-more-minus {
   width: 30%;
   display: flex;
   gap: 0.3125rem;
   align-items: center;

   .btn-minus {
      width: 3.125rem;
      background: var(--red);
      color: var(--white-700);
   }

   .btn-more {
      width: 3.125rem;
      background: var(--primary-color);
      color: var(--white-700);
   }
}



.warning-18 {
   background: var(--red);
   text-align: center;
   padding: 0.625rem;
   border-radius: 0.3125rem;
   color: var(--white-700);
   margin-bottom: 0.625rem;

   i {
      margin-right: 0.3125rem;
   }
}

.warning-18-list {
   color: var(--red);
   font-size: 1.1rem;
   margin-left: 0.625rem;
}

.all-you-can-eat {
   text-align: center;
   padding: 0.125rem;
   border-radius: 1rem;
   color: #705454;
   border: 0.0625rem solid;
   margin-bottom: 0.625rem;
   font-size: 0.75rem !important;

   &.list {
      font-size: 0.8rem;
   }

   i {
      margin-right: 0.3125rem;
   }
}

.toast-container {
   .ngx-toastr {
      width: 100% !important;
      margin-left: auto;
      margin-right: auto;

      &.toast-success {
         background-color: var(--green);
      }
   }
}


//-material-Angular--------------------------------------------------
.mat-typography {
   p {
      margin: 0 !important;
      width: max-content;
   }
}


.mat-radio-label {
   justify-content: space-between !important;
   flex-direction: row-reverse !important;
   white-space: normal !important;
   gap: 5px !important;
 }
 
 .mat-radio-label-content {
   padding: 0 !important;
   width: 100%;
 }
 
 .mat-radio-outer-circle {
   border-width: 0.125rem !important;
   border-color: var(--primary-color) !important;
 }
 
 span.mat-radio-container {
   display: grid !important;
 }

 .mat-progress-spinner circle,
 .mat-spinner circle {
   stroke: var(--primary-color) !important;
 }
 
 .mat-form-field-appearance-legacy {
   .mat-form-field-label {
     color: var(--input-search) !important;
   }
 
   .mat-form-field-underline {
     background-color: var(--border-products) !important;
   }
 }
 
 mat-form-field {
   width: 100%;
 }
 
 .mat-select-arrow {
   color: var(--text-primary) !important;
 }
 
 .mat-input-element {
   padding: 5px !important;
   color: var(--text-primary) !important;
 }
 
 .mat-bottom-sheet-container {
   background: var(--background-app) !important;
 }
 
 .mat-radio-label {
   justify-content: space-between !important;
   flex-direction: row-reverse !important;
 }
 
 .mat-radio-label-content {
   padding: 0 !important;
 }
 
 .mat-radio-outer-circle {
   border-width: 0.0625rem !important;
   border-color: var(--text-primary) !important;
 }
 
 .mat-radio-inner-circle {
   background-color: var(--text-primary) !important;
 }
 
 .mat-progress-spinner circle,
 .mat-spinner circle {
   stroke: var(--primary-color) !important;
 }
 
 span.mat-radio-container {
   display: grid !important;
 }
 
 .mat-tab-label-content {
   color: var(--text-primary) !important;
 }
 
 //-bottom-sheet-----------------------------------------------
 .header-bottom-sheet {
   text-align: end;
   padding-top: 10px;
 
   i {
     font-size: 1.3rem;
     color: var(--text-primary);
   }
 }
 
 .bottom-sheet-content {
   padding: 20px 10px;

   button {
      width: 100%;
      background: var(--primary-color);
   }
 }
 
